body {
  margin: 0;
  padding: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

div.form-custom-content{
  max-width:800px;
  padding:30px 30px 20px 30px;
  background-color:#f2f2f2;
  border-radius:5px;
  margin:100px auto 0 auto;
}
a.navbar-brand{
  line-height:20px;
}
img.img-navbar{
  max-height:36px;
}
.custom-datepicker, .react-datepicker-wrapper, .react-datepicker__input-container{
  width:100% !important;
}
input.form-control{
  text-align: center;
}
div.home-custom-content{
  text-align: center;
}
div.home-custom-content table{
  margin:auto;
}
.custom-icon-remove{
  cursor:pointer;
  font-size:13px;
}
span.month-title{
  cursor:pointer;
  padding:3px 16px;
  border-radius:5px;
}
span.custom-total{
  padding:3px 16px;
  border-radius:5px;
  background-color:#343a40;
  color:white;
}
table.custom-month-table tr td{
  vertical-align:middle;
}

span.month-title.active{
  background-color:#ffc107;
}
span.month-title:hover:not(.active){
  background-color: #efefef;
}

select.custom-month-select{
  width:auto;
  float:right;
}
.custom-button-insert{
  float:left;
}
select.custom-month-select, .custom-button-insert{
  margin-top:-10px;
  margin-bottom:10px;
}
div.despesa-inserida{
  width:100%;
  text-align:left;
  margin-top:-10px;
  margin-bottom:10px;
}
.form-custom-content .form-group{
  position:relative;
}
.form-custom-content input.form-control, .form-custom-content button{
  padding:10px 20px;
}
.form-custom-content select{
  padding-left:30px;
  padding-right:20px !important;
  text-align:center;
  text-align-last:center;
}
.form-custom-content select option{
  padding-left:20px;
  padding-right:20px;
  text-align:center;
}
.form-custom-content .form-group .svg-inline--fa{
  position:absolute;
  left:16px; top:9px;
  font-size:20px;
  z-index:2;
  color:grey;
}
.form-custom-content .react-datepicker-popper{
  z-index:6 !important;
}

.custom-content-form .form-group{
  position:relative;
}
.custom-content-form input.form-control, .custom-content-form button{
  padding:10px 20px;
}
.custom-content-form select{
  padding-left:30px;
  padding-right:20px !important;
  text-align:center;
  text-align-last:center;
}
.custom-content-form select option{
  padding-left:20px;
  padding-right:20px;
  text-align:center;
}
.custom-content-form .form-group .svg-inline--fa{
  position:absolute;
  left:16px; top:9px;
  font-size:20px;
  z-index:2;
  color:grey;
}
.custom-content-form .react-datepicker-popper{
  z-index:6 !important;
}

a.custom-btn-navlink{
  border:1px solid #ffc107;
  padding-left:80px !important;
  padding-right:80px !important;
}
a.custom-btn-navlink.active{
  background-color:#ffc107;
  color:#212529 !important;
}
.custom-icons-navbar{
  font-size:16px;
}
.content-editable{
  display:inline-block;
}

ul.formErrors{
  margin:10px;
  padding:0;
  color:#dc3545;
  list-style:none;
}
table.custom-day-container{
  border:0;
  border-collapse: separate;
  border-spacing:0;
}
table.custom-day-container td.custom-field-day-top{
  font-size:8px;
  background-color:black;
  color:white;
  padding:1px 2px;
  border-top-left-radius:.25em;
  border-top-right-radius:.25em;
  border:1px solid black;
}
table.custom-day-container td.custom-field-day{
  font-size:9px;
  border-right:1px solid lightgrey;
  border-left:1px solid lightgrey;
  border-bottom:1px solid lightgrey;
  border-top:0;
  padding:0 2px;
  border-bottom-left-radius:.25em;
  border-bottom-right-radius:.25em;
  background-color:white;
}
nav.navbar{
  position:absolute;
  height:60px;
  width:100%;
  justify-content: space-between;
}
main{
  float:right;
  width:100%;
  transition:all 0.3s ease-in-out;
}

/** BADGES COLORS DEFINITION **/
.badge{
  margin-left:8px;
}
.badge-adricuti{
  background-color:aqua;
  color:white;
}
.badge-ebella{
  background-color:fuchsia;
  color:white;
}
.badge-emma{
  background-color:purple;
  color:white;
}
.badge-sandra{
  background-color:navy;
  color:white;
}
.badge-gossos{
  background-color:black;
  color:white;
}
.badge-llar{
  background-color:yellow;
  color:black;
}
.badge-supers{
  background-color:olive;
  color:white;
}
.badge-deldia{
  background-color:red;
  color:white;
}
.badge-cotxe{
  background-color:white;
  color:black;
  border:1px solid #999;
}
.badge-moto{
  background-color:blue;
  color:white;
}
.badge-oci{
  background-color:teal;
  color:white;
}
.badge-menjarfora{
  background-color:silver;
  color:white;
}
.badge-compromisos{
  background-color:green;
  color:white;
}
.badge-tramits{
  background-color:lime;
  color:white;
}
.badge-altres{
  background-color:maroon;
  color:white;
}
.btn-insert-form{
  width:100%;
}
/** END BADGES COLORS DEFINITION **/


@media (max-width: 768px){
  div.content-page{
    padding:80px 20px 60px 20px;
  }
  li.nav-item{
    padding:20px 0;
  }
  a.nav-link{
    margin-top:8px;
  }
  li.nav-item .btn-info{
    float:right;
    margin-top:-25px;
  }
  div.hidden-navbar{
    position:fixed;
    bottom:0;
    left:0;
    height:50px;
    width:100%;
    background-color: #343a40 !important;
    overflow:hidden;
    color:white;
  }
  div.hidden-navbar div.list-navigation{
    display:flex;
    justify-content: space-around;
    opacity:1;
  }
  div.hidden-navbar div.list-navigation > div{
    text-align:center;
    padding:15px 0;
  }
  div.hidden-navbar div.list-navigation > div.active{
    color:#ffc107;
  }
  div.hidden-navbar div.list-navigation > div .custom-icons-navbar-lateral{
    font-size:20px;
  }
  div.hidden-navbar div.list-navigation > div span, .custom-sign-out span{
    display:none;
  }
  div.hidden-navbar div.list-navigation > div:last-child, div.hidden-navbar div.list-navigation > div:nth-child(3){
    display:none;
  }
  nav.navbar{
    height:50px;
    padding: 2px 1rem;
  }
  img.img-navbar{
    max-height:30px;
  }
  div.custom-sign-out{
    border-radius:50%;
    border:2px solid white;
    width:30px; height:30px;
    color:white;
    padding:1px 7px;
  }
  div.navigation-top button.navbar-toggler{
    display:none;
  }

}

@media (min-width: 769px){
  div.content-page{
    padding:80px 20px 40px 20px;
  }
  a.nav-link{
    display:inline !important;
  }
  a.no-border{
    border-radius:0;
  }
  a.no-border-right{
    border-bottom-right-radius:0;
    border-top-right-radius:0;
  }
  a.no-border-left{
    border-bottom-left-radius:0;
    border-top-left-radius:0;
  }

  div.hidden-navbar{
    float:left;
    position:fixed;
    padding-top:80px;
    height:100vh;
    width:0;
    background-color: #343a40 !important;
    overflow:hidden;
    transition:all 0.3s ease-in-out;
  }
  main.active{
    width:84%;
  }
  div.hidden-navbar.active{
    width:16%;
  }
  div.hidden-navbar.active div.list-navigation{
    opacity:1;
  }
  div.hidden-navbar div.list-navigation{
    display:flex;
    flex-direction:column;
    opacity:0;
    width:90%;
    padding:10px 10% 0 10%;
    overflow:hidden;
    transition:opacity 0.3s ease-in;
    height:calc(100% - 90px);
  }
  div.hidden-navbar.active div.list-navigation > div{
    opacity:1;
  }

  div.hidden-navbar div.list-navigation > div{
    color:white;
    padding:6px 0;
    opacity:0;
    transition:opacity 0.2s ease-in;
  }
  div.hidden-navbar div.list-navigation > div.active{
    color:#ffc107;
  }
  div.hidden-navbar div.list-navigation > div:not(:last-child){
    border-bottom:1px solid white;
  }

  div.hidden-navbar div.list-navigation > div:nth-child(2){
    border-bottom:0;
  }
  div.hidden-navbar div.list-navigation > div:nth-child(3){
    flex:1;
  }
  div.hidden-navbar div.list-navigation > div span{
    cursor:pointer;
  }

  .custom-icons-navbar-lateral{
    font-size:18px;
  }
  .navbar-expand-md .navbar-toggler{
    display:flex !important;
  }
  div.navigation-top div.custom-sign-out{
    display:none;
  }

}
